import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  props: {
    // 查询的数据源
    data: {
      type: Array
    }
  },
  emits: ['targetdata', 'cursorUpHandleParent'],
  setup: function setup(props, context) {
    var router = useRouter(); // 返回到父组件的目标数组

    var targetData = ref([]);
    var inputVal = ref(''); // 搜索输入框的内容

    var searchListData = function searchListData() {
      targetData.value = [];
      var sourceData = JSON.parse(JSON.stringify(props.data));
      sourceData.forEach(function (item, index) {
        if (item.title.indexOf(inputVal.value) !== -1) {
          targetData.value.push(sourceData[index]);
        }
      });
      context.emit('targetdata', targetData.value);
    }; // 监听输入框内容变化


    var onChangeInput = function onChangeInput() {
      searchListData();
    }; // 清除输入框内容


    var clearSearchInput = function clearSearchInput() {
      inputVal.value = '';
      searchListData();
    }; // 点击前往用户中心


    var goToUserCenter = function goToUserCenter() {
      router.push({
        name: 'UserCenter'
      });
    }; // 手机键盘时间


    var handleKeyUp = function handleKeyUp(event) {
      if (event.keyCode === 13) {
        context.emit('cursorUpHandleParent');
      }
    }; // 光标离开


    var inputBlurHandle = function inputBlurHandle() {
      context.emit('cursorUpHandleParent');
    };

    return {
      inputVal: inputVal,
      onChangeInput: onChangeInput,
      clearSearchInput: clearSearchInput,
      goToUserCenter: goToUserCenter,
      handleKeyUp: handleKeyUp,
      inputBlurHandle: inputBlurHandle
    };
  }
});