import _toConsumableArray from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var store = useStore();
    var students = ref();
    var tableData = computed(function () {
      return store.state.TutorsStore.tutorsList;
    }); // 跳转到表单页面

    var handleGoSheet = function handleGoSheet() {
      router.push({
        name: 'AdminTutorsSheet'
      });
    };

    var tableRowClassName = function tableRowClassName(_ref) {
      var row = _ref.row;

      if (row.is_cancel) {
        return 'line_through';
      }

      return '';
    }; // 处理点击是否预约事件


    var handleOkCellClick = function handleOkCellClick(row, column) {
      // 是否预约
      if (column.no === 7) {
        row.is_ok = !row.is_ok;
        var item = handleObj2Str(row);
        store.dispatch('fetchUpdateTutors', {
          data: item
        }).then(function () {// store.dispatch('fetchGetTutors', { searchParams: { page: 1, limit: 500 } })
        });
      } // 是否取消


      if (column.no === 8) {
        row.is_cancel = !row.is_cancel;

        var _item = handleObj2Str(row);

        store.dispatch('fetchUpdateTutors', {
          data: _item
        }).then(function () {// store.dispatch('fetchGetTutors', { searchParams: { page: 1, limit: 500 } })
        });
      }
    }; // 对象转字符串


    var handleObj2Str = function handleObj2Str(item) {
      var newItem = {};

      for (var _i = 0, _Object$keys = Object.keys(item); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];

        if (['[object Object]', '[object Array]'].indexOf(Object.prototype.toString.call(item[key])) !== -1) {
          newItem[key] = JSON.stringify(item[key]);
        } else {
          newItem[key] = item[key];
        }
      }

      return newItem;
    }; // 筛选是否预约


    var filterOk = function filterOk(value, row) {
      return row.is_ok === value;
    }; // 筛选是否取消


    var filterCancel = function filterCancel(value, row) {
      return row.is_cancel === value;
    }; // 筛选指导类型


    var filterMode = function filterMode(value, row) {
      return row.mode.id === value;
    }; // 筛选服务产品


    var filterProducts = function filterProducts(value, row) {
      return row.products.id === value;
    }; // 筛选咨询师


    var filterCounselors = function filterCounselors(value, row) {
      return row.counselors.id === value;
    }; // 筛选学生


    var filterStudent = function filterStudent(value, row) {
      return row.student === value;
    }; // 筛选登录用户


    var filterLoginUser = function filterLoginUser(value, row) {
      return row.login_user === value;
    };

    onMounted(function () {
      store.dispatch('fetchGetTutors', {
        searchParams: {
          page: 1,
          limit: 500
        }
      }).then(function () {
        var studentsK = new Map();

        for (var index in tableData.value) {
          var stu = tableData.value[index].student;
          studentsK.set(stu, {
            text: stu,
            value: stu
          });
        }

        students.value = _toConsumableArray(studentsK.values());
      });
    });
    return {
      students: students,
      tableData: tableData,
      filterOk: filterOk,
      filterCancel: filterCancel,
      filterMode: filterMode,
      filterProducts: filterProducts,
      filterCounselors: filterCounselors,
      filterStudent: filterStudent,
      filterLoginUser: filterLoginUser,
      handleGoSheet: handleGoSheet,
      tableRowClassName: tableRowClassName,
      handleOkCellClick: handleOkCellClick
    };
  }
});