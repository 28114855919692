import _slicedToArray from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import { ref, defineComponent, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import Calendar from '@/components/calendar.vue';
import { getStorage } from '@/utils/storage';
import Linegram from '@/components/charts/Linegram.vue';
import affordOrder from '../../components/affordOrder.vue';
export default defineComponent({
  components: {
    Calendar: Calendar,
    Linegram: Linegram,
    affordOrder: affordOrder
  },
  setup: function setup() {
    var store = useStore();
    var tagVisible = ref('day');
    var dayTime = ref('');
    var weekTime = ref('');
    var monthTime = ref('');
    var showMonth = ref(7);
    var calendarDayVisible = ref(false);
    var calendarWeekVisible = ref(false);
    var calendarMonthVisible = ref(false);
    var lineChildRef = ref();
    var showData = reactive({
      start: '',
      end: ''
    });
    var timeDate = ref('');

    var handleChangeTag = function handleChangeTag(type) {
      tagVisible.value = type;

      switch (tagVisible.value) {
        case 'day':
          timeDate.value = dayTime.value;
          break;

        case 'week':
          timeDate.value = weekTime.value.replace('~', ',');
          break;

        case 'month':
          timeDate.value = monthTime.value;
          break;

        default:
          break;
      }

      getOptionData(tagVisible.value, timeDate.value);
    };

    var fnCallLineChild = function fnCallLineChild() {
      lineChildRef.value.doLineDraw();
    };

    var getOptionData = function getOptionData(pattern, time) {
      store.dispatch('fetchOrderList', {
        searchParams: {
          pattern: pattern,
          time: time,
          retail_id: store.state.UserStore.userInfo.retailNum,
          page: 1,
          limit: 10
        }
      }).then(function () {
        fnCallLineChild();
      });
    };

    var handleOpenCalendar = function handleOpenCalendar() {
      switch (tagVisible.value) {
        case 'day':
          calendarDayVisible.value = true;
          break;

        case 'week':
          calendarWeekVisible.value = true;
          break;

        case 'month':
          calendarMonthVisible.value = true;
          break;

        default:
          break;
      }
    };

    var handleCancelCalendar = function handleCancelCalendar() {
      calendarMonthVisible.value = false;
    };

    var onConfirmDay = function onConfirmDay(value) {
      calendarDayVisible.value = false; // 点击确认后恢复默认值，收回日历弹窗

      dayTime.value = dayjs(value).format('YYYY-MM-DD');
      timeDate.value = dayTime.value;
      getOptionData('date', dayTime.value);
    };

    var onConfirmWeek = function onConfirmWeek(values) {
      calendarWeekVisible.value = false;

      var _values = _slicedToArray(values, 2),
          start = _values[0],
          end = _values[1];

      weekTime.value = dayjs(start).format('YYYY-MM-DD') + '~' + dayjs(end).format('YYYY-MM-DD');
      timeDate.value = weekTime.value.replace('~', ',');
      getOptionData('week', weekTime.value.replace('~', ','));
    };

    var onConfirmMonth = function onConfirmMonth(value) {
      calendarMonthVisible.value = false;
      monthTime.value = dayjs(value).format('YYYY-MM');
      timeDate.value = monthTime.value;
      getOptionData('month', monthTime.value);
    };

    var setShowData = function setShowData() {
      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      showData.end = new Date(year, month - 1, day); // 当前月份 - 可展示月份 < 1  0或负数

      if (month - showMonth.value < 1) {
        // 保证月份相减不为负数，提前1年
        year--; // 总月数 - （ 可展示月份 - 1）

        month = 12 - (showMonth.value - month); // 开始的月份
      } else {
        month = month - showMonth.value;
      } // Date对象是0~11，没有12，所以要减去1


      showData.start = new Date(year, month - 1, day);
    };

    var getDateData = function getDateData() {
      dayTime.value = getStorage({
        type: 'localStorage',
        key: 'dayTime',
        isEncrypt: false
      }) || dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      weekTime.value = getStorage({
        type: 'localStorage',
        key: 'weekTime',
        isEncrypt: false
      }) || "".concat(dayjs().subtract(7, 'day').format('YYYY-MM-DD'), "~").concat(dayjs().subtract(1, 'day').format('YYYY-MM-DD')); // weekTime.value = getStorage({ type: 'localStorage', key: 'weekTime', isEncrypt: false }) || dayjs().subtract(7, 'day').format('YYYY-MM-DD') + '~' + dayjs().subtract(1, 'day').format('YYYY-MM-DD')

      monthTime.value = getStorage({
        type: 'localStorage',
        key: 'monthTime',
        isEncrypt: false
      }) || dayjs().subtract(1, 'month').format('YYYY-MM');
    };

    getDateData();
    setShowData();
    onMounted(function () {
      getOptionData('date', dayTime.value);
    }); // 获取子组件页数

    var getPageNumber = function getPageNumber(page) {
      store.dispatch('fetchOrderList', {
        searchParams: {
          pattern: tagVisible.value,
          time: timeDate.value,
          retail_id: store.state.UserStore.userInfo.retailNum,
          page: page,
          limit: 10
        }
      });
    };

    return {
      tagVisible: tagVisible,
      dayTime: dayTime,
      weekTime: weekTime,
      monthTime: monthTime,
      showData: showData,
      lineChildRef: lineChildRef,
      calendarDayVisible: calendarDayVisible,
      calendarWeekVisible: calendarWeekVisible,
      calendarMonthVisible: calendarMonthVisible,
      handleChangeTag: handleChangeTag,
      handleOpenCalendar: handleOpenCalendar,
      handleCancelCalendar: handleCancelCalendar,
      onConfirmDay: onConfirmDay,
      onConfirmWeek: onConfirmWeek,
      onConfirmMonth: onConfirmMonth,
      getPageNumber: getPageNumber
    };
  }
});