import { ref, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  emits: ['givePageNumber'],
  setup: function setup(props, context) {
    var store = useStore();
    var currentPage = ref(1);
    var perPageCount = ref(10);
    var currentInfoList = computed(function () {
      return store.state.StatisticsStore.OrderList;
    });
    var InfoList = [{
      id: 1,
      time: '11:46',
      price: 3.65
    }, {
      id: 2,
      time: '11:46',
      price: 3.65
    }, {
      id: 3,
      time: '11:46',
      price: 3.65
    }, {
      id: 4,
      time: '11:46',
      price: 3.65
    }, {
      id: 5,
      time: '11:46',
      price: 3.65
    }, {
      id: 6,
      time: '11:46',
      price: 3.65
    }, {
      id: 7,
      time: '11:46',
      price: 3.65
    }, {
      id: 8,
      time: '11:46',
      price: 3.65
    }, {
      id: 9,
      time: '11:46',
      price: 3.65
    }, {
      id: 10,
      time: '11:46',
      price: 3.65
    }, {
      id: 11,
      time: '11:46',
      price: 3.65
    }, {
      id: 12,
      time: '11:46',
      price: 3.65
    }];

    var changePage = function changePage(event) {
      context.emit('givePageNumber', event);
    };

    return {
      InfoList: InfoList,
      currentPage: currentPage,
      currentInfoList: currentInfoList,
      perPageCount: perPageCount,
      changePage: changePage
    };
  }
});