import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'home',
  setup: function setup() {
    var router = useRouter();

    var gotoDetails = function gotoDetails() {
      router.push({
        name: 'Detail',
        params: {
          id: 1
        },
        query: {
          plan: 'a'
        }
      });
    };

    return {
      gotoDetails: gotoDetails
    };
  }
});