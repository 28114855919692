import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { ref, defineComponent, onMounted, watch } from 'vue';
export default defineComponent({
  emits: ['allData', 'clickGood'],
  props: {
    selectList: {
      type: Array
    }
  },
  setup: function setup(props, context) {
    watch(function () {
      return props.selectList;
    }, function () {
      targetData.value = props.selectList;
    });
    var state = ref(false);
    var inputVal = ref('');
    var selectedId = ref(-1);
    var selectedItem = ref({});
    var targetData = ref([]);

    var selectedRetail = function selectedRetail(item) {
      selectedId.value = item.id;
      selectedItem.value = item;
    };

    var onChangeInput = function onChangeInput() {
      targetData.value = [];
      var sourceData = JSON.parse(JSON.stringify(props.selectList));
      console.log('inputVal', inputVal);
      sourceData.forEach(function (item, index) {
        if (item.title.indexOf(inputVal.value) !== -1) {
          targetData.value.push(sourceData[index]); // console.log(targetData.value.length, '长度')

          console.log('targetData', targetData.value);
        }
      });
    };

    var handleChioceGood = function handleChioceGood() {
      context.emit('clickGood', selectedItem.value);
    };

    onMounted(function () {
      targetData.value = props.selectList;
    });
    return {
      state: state,
      selectedId: selectedId,
      inputVal: inputVal,
      targetData: targetData,
      selectedRetail: selectedRetail,
      onChangeInput: onChangeInput,
      handleChioceGood: handleChioceGood
    };
  }
});