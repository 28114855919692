import { defineComponent, ref } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  setup: function setup(props, context) {
    var clickData = ref('');
    var monthData = ref([]);

    var handleCancel = function handleCancel() {
      context.emit('cancel', false);
    };

    var handleOk = function handleOk() {
      context.emit('ok', clickData.value);
    };

    var handleSet = function handleSet() {
      var date = new Date(); // 当前年

      var year = date.getFullYear(); // 当前月

      var month = date.getMonth() + 1;

      for (var i = year; i > year - 2; i--) {
        var mon = {
          number: year === i ? month : 12,
          title: i + ''
        };
        monthData.value.unshift(mon);
      }
    };

    handleSet();

    var handleClickDate = function handleClickDate(number, item) {
      clickData.value = dayjs(item.title + '-' + number).format('YYYY-MM');
    };

    return {
      monthData: monthData,
      handleCancel: handleCancel,
      handleOk: handleOk,
      handleClickDate: handleClickDate
    };
  }
});