import { ref, defineComponent } from 'vue';
export default defineComponent({
  setup: function setup() {
    var selectTitle = ref(false);
    var menuList = [{
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }, {
      name: '公爵'
    }];

    var clickTitle = function clickTitle() {
      selectTitle.value = true;
    };

    return {
      menuList: menuList,
      selectTitle: selectTitle,
      clickTitle: clickTitle
    };
  }
});