import { ref, defineComponent } from 'vue'; // import { useStore } from 'vuex'

export default defineComponent({
  emits: ['isClick'],
  props: {
    isClick: {
      type: Boolean
    },
    data: {
      type: Object
    },
    detail: {
      type: Object
    }
  },
  setup: function setup(props, context) {
    var Click = ref(props.isClick);

    var handleToCancel = function handleToCancel() {
      Click.value = false;
      context.emit('isClick', Click.value);
    };

    return {
      Click: Click,
      handleToCancel: handleToCancel
    };
  }
});