import _objectSpread from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { ref, defineComponent, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  emits: ['allData'],
  setup: function setup(props, context) {
    var store = useStore();
    var state = ref('');
    var inputVal = ref('');
    var showMask = ref(false);
    var Toshow = ref(true);
    var retailItem = ref({});
    var channelItem = ref({});
    var targetData = ref([]);
    var selectedId = ref('');
    var actived = ref(false);

    var selectedRetail = function selectedRetail(item) {
      state.value = item.retailObj.id;
      showMask.value = true;
      retailItem.value = item;
    };

    var selectedChannel = function selectedChannel(item) {
      selectedId.value = item.id;
      channelItem.value = item;
      actived.value = selectedId.value === item.id;
    };

    var cancleActionSheet = function cancleActionSheet() {
      showMask.value = false;
      Toshow.value = false;
      actived.value = false;
      state.value = '';
    };

    var closeMask = function closeMask() {
      if (actived.value === true) {
        showMask.value = false;
        Toshow.value = false;
        actived.value = false;
        state.value = '';
        selectedId.value = '';
        context.emit('allData', _objectSpread(_objectSpread({}, retailItem.value), {}, {
          channelObj: _objectSpread({}, channelItem.value)
        }), Toshow);
      } else {
        alert('请选择统计方式');
      }
    };

    var channelList = [{
      id: 'a',
      name: 'business-component-a'
    }];
    var retailList = computed(function () {
      return store.state.AdminStore.retailList;
    });

    var onChangeInput = function onChangeInput() {
      targetData.value = [];
      retailList.value.forEach(function (item, index) {
        if (item.title.indexOf(inputVal.value) !== -1) {
          targetData.value.push(retailList.value[index]);
        }
      });
    };

    onMounted(function () {
      store.dispatch('fetchRetailAdminList', {
        searchParams: {
          retail_id: store.state.UserStore.userInfo.retailNum
        }
      });
    });
    return {
      state: state,
      channelList: channelList,
      showMask: showMask,
      retailList: retailList,
      inputVal: inputVal,
      actived: actived,
      selectedId: selectedId,
      targetData: targetData,
      selectedRetail: selectedRetail,
      selectedChannel: selectedChannel,
      closeMask: closeMask,
      onChangeInput: onChangeInput,
      cancleActionSheet: cancleActionSheet
    };
  }
});