import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_notice_bar = _resolveComponent("van-notice-bar");

  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_router_link = _resolveComponent("router-link");

  var _component_el_link = _resolveComponent("el-link");

  var _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_notice_bar, {
    "left-icon": "volume-o",
    text: "建立钉钉群，初次指导时间确认后，邀请初次指导老师、学员建立指导群（群名：校招助手+学员编号，上传简历、学员档案至指导群"
  }), _createVNode(_component_el_table, {
    border: "",
    data: _ctx.tableData,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        type: "index",
        width: "50"
      }), _createVNode(_component_el_table_column, {
        label: "编号",
        width: "80"
      }, {
        default: _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.row.staff) + _toDisplayString(scope.row.staffNum), 1)];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        prop: "username",
        label: "姓名",
        width: "80"
      }), _createVNode(_component_el_table_column, {
        prop: "school",
        label: "学校",
        width: "100",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        prop: "subject",
        label: "专业",
        width: "100"
      }), _createVNode(_component_el_table_column, {
        prop: "education",
        label: "最高学历",
        width: "100"
      }), _createVNode(_component_el_table_column, {
        prop: "graduationTime",
        label: "毕业时间",
        width: "100"
      }), _createVNode(_component_el_table_column, {
        prop: "recruit",
        label: "招聘类型",
        width: "100",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        prop: "trade",
        label: "意向行业",
        width: "100",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        prop: "station",
        label: "目标岗位",
        width: "100",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        prop: "company",
        label: "目标企业",
        width: "100",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        prop: "jobProblems",
        label: "求职问题",
        "show-overflow-tooltip": true
      }), _createVNode(_component_el_table_column, {
        label: "学员档案",
        width: "120",
        "show-overflow-tooltip": true
      }, {
        default: _withCtx(function (scope) {
          return [_createVNode(_component_router_link, {
            to: {
              name: 'StudentVTable',
              query: {
                _id: scope.row._id
              }
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.username) + "-" + _toDisplayString(scope.row.staff) + _toDisplayString(scope.row.staffNum) + "学员档案 ", 1)];
            }),
            _: 2
          }, 1032, ["to"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        label: "简历预览/下载",
        width: "200",
        "show-overflow-tooltip": true
      }, {
        default: _withCtx(function (scope) {
          return [_createVNode(_component_el_link, {
            href: scope.row.resumeFileUrl,
            target: "_blank"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.resumeFileUrl.split('/').slice(-1)[0]), 1)];
            }),
            _: 2
          }, 1032, ["href"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        prop: "staffName",
        label: "咨询师",
        width: "100"
      }), _createVNode(_component_el_table_column, {
        label: "创建时间",
        width: "160",
        sortable: "",
        "sort-method": _ctx.timeSortMethodHandle
      }, {
        default: _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(_ctx.parseTime(scope.row.created_time, '{y}-{m}-{d} {h}:{i}')), 1)];
        }),
        _: 1
      }, 8, ["sort-method"])];
    }),
    _: 1
  }, 8, ["data"])], 64);
}