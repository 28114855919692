import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6c0efdc7"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "goods_items"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "goods_text"
};
var _hoisted_5 = {
  class: "goods_title"
};
var _hoisted_6 = {
  class: "goods_detail_text"
};
var _hoisted_7 = {
  class: "goods_price"
};
var _hoisted_8 = {
  class: "goods_original_price"
};
var _hoisted_9 = {
  class: "goods_study_count"
};
var _hoisted_10 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_search_bar = _resolveComponent("search-bar");

  return _openBlock(), _createElementBlock("div", {
    class: "goods_index",
    onScroll: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.onScroll && _ctx.onScroll.apply(_ctx, arguments);
    }),
    ref: "goodsItemEle"
  }, [_createVNode(_component_search_bar, {
    data: _ctx.searchData.items,
    onTargetdata: _ctx.getAllData,
    onCursorUpHandleParent: _ctx.cursorUpHandle
  }, null, 8, ["data", "onTargetdata", "onCursorUpHandleParent"]), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsListData, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "goods_item",
      key: 'kp-shop' + index,
      onClick: function onClick($event) {
        return _ctx.goToGoodDetail(item);
      }
    }, [_createElementVNode("img", {
      src: item.cover,
      alt: ""
    }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, "￥" + _toDisplayString(item.price), 1), _createElementVNode("span", _hoisted_8, "￥" + _toDisplayString(item.params.originalPrice), 1), _createElementVNode("span", _hoisted_9, _toDisplayString(item.params.buyers) + "人已买", 1)])])], 8, _hoisted_2);
  }), 128)), !_ctx.goodsListData.length ? (_openBlock(), _createElementBlock("div", _hoisted_10, "暂无数据")) : _createCommentVNode("", true)])], 544);
}