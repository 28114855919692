import { defineComponent, ref } from 'vue';
import { randomNum } from '@/utils';
export default defineComponent({
  setup: function setup() {
    var img = ref();
    var codes = ['https://kepinfm.oss-cn-beijing.aliyuncs.com/code/group/10.png', 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/group/9.png'];
    img.value = codes[randomNum(2, -1)];
    return {
      img: img
    };
  }
});