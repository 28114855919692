import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  style: {
    "height": "100vh",
    "background-color": "#f5f3eb"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_notice_bar = _resolveComponent("van-notice-bar");

  var _component_van_grid_item = _resolveComponent("van-grid-item");

  var _component_van_grid = _resolveComponent("van-grid");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_notice_bar, {
    "left-icon": "volume-o",
    text: "点击选择咨询师，把⬆️校招助手信息登记表单地址⬆️发给学员"
  }), _createVNode(_component_van_grid, {
    clickable: "",
    square: "",
    gutter: 10,
    style: {
      "margin-top": "10px"
    }
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CONSULTANTS, function (item, idx) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: idx,
          text: item.text + '-' + item.value,
          onClick: function onClick($event) {
            return _ctx.toSheetPageHandle(item);
          }
        }, null, 8, ["text", "onClick"]);
      }), 128))];
    }),
    _: 1
  })]);
}