import _objectSpread from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { range, find } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import 'vant/es/dialog/style';
import { uploadFileQn } from '@/utils/qn-kodo';
import { CONSULTANTS } from '@/utils/index';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var store = useStore();
    var container = ref();
    var percentage = ref(0);
    var username = ref('');
    var school = ref('');
    var education = ref('');
    var subject = ref('');
    var trade = ref('');
    var station = ref('');
    var graduationTime = ref('');
    var showPicker = ref(false);
    var recruit = ref([]);
    var company = ref('');
    var jobProblems = ref('');
    var fileList = ref();
    var totalNum = 9;
    var fileKey = ref('');
    var showOverlay = ref(false);
    var activeStep = ref(0);
    var fileMeta = computed(function () {
      return store.state.AdminStore.fileMeta;
    });
    var staff = route.query.v;
    onMounted(function () {
      if (staff === '') {
        showOverlay.value = true;
      }
    });

    var resetForm = function resetForm() {
      percentage.value = 0;
      username.value = '';
      school.value = '';
      education.value = '';
      subject.value = '';
      trade.value = '';
      station.value = '';
      graduationTime.value = '';
      recruit.value = [];
      company.value = '';
      jobProblems.value = '';
      fileList.value = [];
      fileKey.value = '';
    };

    var graduationTimeOptionsChildren = [];

    var _iterator = _createForOfIteratorHelper(range(12, 0)),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var v = _step.value;
        graduationTimeOptionsChildren.push({
          text: "".concat(v, "\u6708"),
          value: "".concat(v, "\u6708")
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    var graduationTimeOptions = [];

    var _iterator2 = _createForOfIteratorHelper(range(25, 19)),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _v = _step2.value;
        graduationTimeOptions.push({
          text: "".concat(_v, "\u5E74"),
          value: "".concat(_v, "\u5E74"),
          children: graduationTimeOptionsChildren
        });
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    var onSubmit = function onSubmit(values) {
      var url = fileMeta.value.url;
      var resumeFileUrl = "".concat(url).concat(fileKey.value);

      if (fileKey.value === '') {
        Toast.fail('简历没有上传');
        return;
      }

      if (values.education === '') {
        Toast.fail('最高学历没有选择');
        return;
      }

      if (values.graduationTime === '') {
        Toast.fail('毕业时间没有选择');
        return;
      }

      if (values.recruit.length === 0) {
        Toast.fail('招聘类型没有选择');
        return;
      }

      var staffObj = find(CONSULTANTS, ['value', staff]);
      var staffName = staffObj ? staffObj.text : '';

      var fData = _objectSpread({
        staff: staff,
        resumeFileUrl: resumeFileUrl,
        staffName: staffName
      }, values);

      fData.recruit = fData.recruit.join('/');
      store.dispatch('postInforTable', {
        data: fData
      }).then(function () {
        resetForm();
        Toast.success('表单提交成功');
        Toast.loading({
          message: '正在跳转...',
          duration: 2000
        });
        activeStep.value = 1;
      });
    };

    var onFinish = function onFinish(_ref) {
      var selectedOptions = _ref.selectedOptions;
      showPicker.value = false;
      graduationTime.value = selectedOptions.map(function (option) {
        return option.text;
      }).join('/');
      commonBlurHandle(graduationTime.value, 'graduationTime');
    };

    var percentageHandle = function percentageHandle(num, total, type) {
      var number = num / total * 100;

      if (type === '+') {
        percentage.value += number;
      } else {
        if (percentage.value > 0) {
          percentage.value -= number;
        }
      }

      percentage.value = percentage.value >= 100 ? 100 : percentage.value;
    };

    var inputBlurVals = [];

    var commonBlurHandle = function commonBlurHandle(val, name) {
      var index = inputBlurVals.indexOf(name);

      if (val && index < 0) {
        percentageHandle(1, totalNum, '+');
        inputBlurVals.push(name);
      }

      if (val === '' && index >= 0) {
        percentageHandle(1, totalNum, '-');
        inputBlurVals.splice(index, 1);
      }
    };

    var InputBlurHandle = function InputBlurHandle(event) {
      var name = event.target.name;

      switch (name) {
        case 'username':
          commonBlurHandle(username.value, name);
          break;

        case 'school':
          commonBlurHandle(school.value, name);
          break;

        case 'subject':
          commonBlurHandle(subject.value, name);
          break;

        case 'trade':
          commonBlurHandle(trade.value, name);
          break;

        case 'station':
          commonBlurHandle(station.value, name);
          break;

        case 'jobProblems':
          commonBlurHandle(jobProblems.value, name);
          break;

        case 'company':
          commonBlurHandle(company.value, name);
          break;

        default:
          break;
      }
    };

    var recruitCheckboxChangeHandle = function recruitCheckboxChangeHandle(names) {
      var name = 'recruitGroupChecked';
      var index = inputBlurVals.indexOf(name);

      if (names.length === 1 && index < 0) {
        percentageHandle(1, totalNum, '+');
        inputBlurVals.push(name);
      }

      if (names.length === 0 && index >= 0) {
        percentageHandle(1, totalNum, '-');
        inputBlurVals.splice(index, 1);
      }
    };

    var educationCheckboxChangeHandle = function educationCheckboxChangeHandle() {
      commonBlurHandle(education.value, 'education');
    };

    var oversizeFileHandle = function oversizeFileHandle() {
      Toast.fail('文件大小不能超过10M');
    };

    var uploadFile = function uploadFile(fileO) {
      var file = fileO.file;
      var _fileMeta$value = fileMeta.value,
          expires = _fileMeta$value.expires,
          token = _fileMeta$value.token;
      var currentTime = Math.ceil(new Date().getTime() / 1000);

      if (expires !== undefined || expires < currentTime) {
        uploadFileQn(file, token).then(function (res) {
          setTimeout(function () {
            fileO.status = 'success';
            fileO.message = '上传成功';
          }, 1000);
          fileKey.value = res.key;
          Toast.success('简历上传成功');
        }).catch(function () {
          fileO.status = 'failed';
          fileO.message = '上传失败';
          setTimeout(function () {
            fileList.value = fileList.value.slice(0, 0);
          }, 1000);
          Toast.fail('简历上传失败');
        });
      } else {
        store.dispatch('getFileMeta', {
          searchParams: {
            bucket: 'kodo'
          }
        }).then(function () {
          var token = fileMeta.value.token;
          uploadFileQn(file, token).then(function (res) {
            setTimeout(function () {
              fileO.status = 'success';
              fileO.message = '上传成功';
            }, 1000);
            fileKey.value = res.key;
            Toast.success('简历上传成功');
          }).catch(function () {
            fileO.status = 'failed';
            fileO.message = '上传失败';
            setTimeout(function () {
              fileList.value = fileList.value.slice(0, 0);
            }, 1000);
            Toast.fail('简历上传失败');
          });
        }).catch(function () {
          fileO.status = 'failed';
          fileO.message = '上传失败';
          setTimeout(function () {
            fileList.value = fileList.value.slice(0, 0);
          }, 1000);
          Toast.fail('简历上传失败');
        });
      }
    };

    var afterReadFileHandle = function afterReadFileHandle(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      uploadFile(file);
    };

    var beforeDeleteFileHandle = function beforeDeleteFileHandle(fileO) {
      var fileName = fileO.file.name;
      Dialog.confirm({
        title: "\u662F\u5426\u5220\u9664".concat(fileName)
      }).then(function () {
        // on confirm
        store.dispatch('delFile', {
          searchParams: {
            bucket: 'kodo',
            key: fileKey.value
          }
        }).then(function () {
          fileList.value = fileList.value.slice(0, 0);
          fileKey.value = '';
          Toast.success('简历删除成功');
        });
      }).catch(function () {// on cancel
      });
    };

    return {
      container: container,
      percentage: percentage,
      username: username,
      school: school,
      education: education,
      subject: subject,
      trade: trade,
      station: station,
      graduationTime: graduationTime,
      graduationTimeOptions: graduationTimeOptions,
      showPicker: showPicker,
      recruit: recruit,
      company: company,
      jobProblems: jobProblems,
      fileList: fileList,
      showOverlay: showOverlay,
      activeStep: activeStep,
      onSubmit: onSubmit,
      onFinish: onFinish,
      InputBlurHandle: InputBlurHandle,
      recruitCheckboxChangeHandle: recruitCheckboxChangeHandle,
      educationCheckboxChangeHandle: educationCheckboxChangeHandle,
      oversizeFileHandle: oversizeFileHandle,
      afterReadFileHandle: afterReadFileHandle,
      beforeDeleteFileHandle: beforeDeleteFileHandle
    };
  }
});