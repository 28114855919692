import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.json.stringify.js";
import { ref, defineComponent, computed, onMounted, reactive } from 'vue';
import { Toast } from 'vant';
import QRCode from 'qrcodejs2';
import { useStore } from 'vuex';
import SelectGoods from './selectGoods.vue';
import { urlSplit, copyToClipboard } from '../../utils/index';
export default defineComponent({
  components: {
    SelectGoods: SelectGoods
  },
  setup: function setup() {
    var store = useStore();
    var addProduct = ref();
    var actionSheetShow = ref(false);
    var dialogErweima = ref(false);
    var retailObj = reactive({
      id: computed(function () {
        return store.state.UserStore.userInfo.retailNum;
      }),
      name: computed(function () {
        return store.state.UserStore.userInfo.nickname;
      })
    });
    var channelObj = reactive({
      id: '',
      name: ''
    });
    var statusObj = reactive({
      channel: '-1',
      retail: '1'
    });
    var productData = computed(function () {
      return store.state.AdminStore.retailList;
    });
    var goodsList = computed(function () {
      return store.state.GoodsStore.goodList.items;
    });
    var shortUrlInfo = computed(function () {
      return store.state.AdminStore.shortUrlInfo;
    });
    var targetGoodsList = ref([]);

    var toAdd = function toAdd() {
      actionSheetShow.value = true;
    };

    var handleScreenData = function handleScreenData() {
      var productIds = [];
      productData.value.forEach(function (item) {
        productIds.push(item.goods_id);
      });
      targetGoodsList.value = goodsList.value.filter(function (goodItem) {
        var id = goodItem.id;

        if (productIds.indexOf(id) !== -1) {
          return false;
        }

        return true;
      });
    };

    var handleChioceGood = function handleChioceGood(data) {
      actionSheetShow.value = false;
      var title = data.title,
          price = data.price,
          cover = data.cover,
          id = data.id;
      var formData = {
        title: title,
        price: price,
        cover: cover,
        goods_id: id,
        retailObj: JSON.stringify(retailObj),
        channelObj: JSON.stringify(channelObj),
        statusObj: JSON.stringify(statusObj)
      };
      store.dispatch('postRetail', {
        data: formData
      }).then(function () {
        Toast.success('添加成功啦~');
        getRetailAdminlList();
      });
    };

    var getRetailAdminlList = function getRetailAdminlList() {
      store.dispatch('fetchRetailAdminList', {
        searchParams: {
          retail_id: retailObj.id,
          page: 1,
          limit: 60
        }
      }).then(function () {
        handleScreenData();
      });
    }; // 链接复制


    var copyAddress = function copyAddress(type, item) {
      var hostName = '';
      var host = 'https://t.kepinfm.com';
      var url = '/detail/:id';
      var newUrl = urlSplit(url, {
        urlParams: {
          id: item.goods_id
        },
        searchParams: {
          uid: retailObj.id
        }
      });

      switch (type) {
        case 'origin':
          hostName = host + newUrl;
          copyToClipboard(hostName);
          break;

        case 'short':
          hostName = host + newUrl;
          store.dispatch('postShortUrl', {
            data: {
              url: hostName,
              retail_num: retailObj.id
            }
          }).then(function () {
            copyToClipboard(shortUrlInfo.value);
          });
          break;

        default:
          break;
      }

      Toast.success('复制成功');
    };

    var getQrcode = function getQrcode(url) {
      var obj = document.getElementById('qrcode');

      if (obj) {
        obj.innerHTML = '';
      } // eslint-disable-next-line no-new


      new QRCode('qrcode', {
        text: url,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    };

    var closeDialogErweima = function closeDialogErweima() {
      var obj = document.getElementById('qrcode');

      if (obj) {
        obj.innerHTML = '';
      }

      dialogErweima.value = false;
    };

    var openDialogErweima = function openDialogErweima(item) {
      var host = 'https://t.kepinfm.com';
      var url = '/detail/:id';
      var newUrl = urlSplit(url, {
        urlParams: {
          id: item.goods_id
        },
        searchParams: {
          uid: retailObj.id
        }
      });
      var hostName = host + newUrl;
      store.dispatch('postShortUrl', {
        data: {
          url: hostName,
          retail_num: retailObj.id
        }
      }).then(function () {
        getQrcode(shortUrlInfo.value);
      });
      dialogErweima.value = true;
    };

    onMounted(function () {
      getRetailAdminlList();
      store.dispatch('fetchGoodList', {
        searchParams: {
          page: 1,
          limit: 60
        }
      });
    });
    return {
      addProduct: addProduct,
      productData: productData,
      goodsList: goodsList,
      actionSheetShow: actionSheetShow,
      targetGoodsList: targetGoodsList,
      dialogErweima: dialogErweima,
      toAdd: toAdd,
      handleChioceGood: handleChioceGood,
      copyAddress: copyAddress,
      openDialogErweima: openDialogErweima,
      closeDialogErweima: closeDialogErweima
    };
  }
});