import { defineComponent, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getStorage } from '../../utils/storage';
export default defineComponent({
  setup: function setup() {
    var store = useStore();
    var userInfo = computed(function () {
      return store.state.UserStore.userInfo;
    });
    var router = useRouter();

    var handleToPage = function handleToPage(name) {
      router.push({
        name: name // params: { id: item.id }

      });
    };

    var storageConfig = {
      key: 'kp_shop_openid_v1',
      type: 'localStorage',
      isEncrypt: true
    };
    var localOpenid = getStorage(storageConfig);
    onMounted(function () {
      store.dispatch('fetchUserWxInfo', {
        searchParams: {
          openid: localOpenid || 'oQ3jX1TRESLTV8BenN8d3xTzllZs'
        }
      });
    });
    return {
      userInfo: userInfo,
      handleToPage: handleToPage
    };
  }
});