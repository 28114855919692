import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "height": "100vh",
    "display": "flex",
    "align-items": "center",
    "text-align": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_image = _resolveComponent("van-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    width: "300",
    height: "300",
    src: _ctx.img ? _ctx.img : 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/555.jpg',
    style: {
      "margin": "0 auto"
    }
  }, null, 8, ["src"])]);
}