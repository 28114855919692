import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import * as echarts from 'echarts';
export default defineComponent({
  props: {
    stateName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    // 1. 定义数据结构
    // 2. 通过 getters 获取到处理过后的数据
    // 3. 父组件调用子组件的方法进行图表数据渲染
    var store = useStore();
    var optionData = {
      title: {// text: 'Stacked Line'
      },
      tooltip: {
        trigger: 'axis'
      },
      color: [],
      legend: {
        data: []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {// saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: []
    };
    var lineOption = computed(function () {
      return store.getters.getLinegramData(optionData, props.stateName);
    });
    var myChart;

    var doLineDraw = function doLineDraw() {
      historyMapInit();
      lineOption.value && myChart.setOption(lineOption.value);
    };

    function historyMapInit() {
      // 防止出现“There is a chart instance already initialized on the dom.”的警告
      // 在使用echarts发现需要及时对新建的myChart实例进行销毁,否则会出现上述警告
      if (myChart != null && myChart !== undefined) {
        myChart.dispose();
      } // eslint-disable-next-line @typescript-eslint/no-non-null-assertion


      var chartDom = document.getElementById('linegram');
      myChart = echarts.init(chartDom);
    }

    return {
      doLineDraw: doLineDraw
    };
  }
});