import _slicedToArray from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import Linegram from '@/components/charts/Linegram.vue';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { getStorage, setStorage } from '@/utils/storage';
import Calendar from '@/components/calendar.vue';
import dayjs from 'dayjs';
export default defineComponent({
  components: {
    Linegram: Linegram,
    Calendar: Calendar
  },
  setup: function setup() {
    var store = useStore();
    var dayTime = ref('');
    var weekTime = ref('');
    var monthTime = ref('');
    var tagVisible = ref('week');
    var showData = reactive({
      start: '',
      end: ''
    });
    var showMonth = ref(7);
    var calendarDayVisible = ref(false);
    var calendarWeekVisible = ref(false);
    var calendarMonthVisible = ref(false);
    var lineChildRef = ref();

    var handleChangeTag = function handleChangeTag(type) {
      tagVisible.value = type;
      var timeDate = '';

      switch (tagVisible.value) {
        case 'day':
          timeDate = dayTime.value;
          break;

        case 'week':
          timeDate = weekTime.value.replace('~', ',');
          break;

        case 'month':
          timeDate = monthTime.value;
          break;

        default:
          break;
      }

      getOptionData(tagVisible.value, timeDate);
    };

    var handleOpenCalendar = function handleOpenCalendar() {
      switch (tagVisible.value) {
        case 'day':
          calendarDayVisible.value = true;
          break;

        case 'week':
          calendarWeekVisible.value = true;
          break;

        case 'month':
          calendarMonthVisible.value = true;
          break;

        default:
          break;
      }
    };

    var handleCancelCalendar = function handleCancelCalendar() {
      calendarMonthVisible.value = false;
    };

    var handleOkCalandar = function handleOkCalandar(type, clickDate) {
      var storage = {
        type: 'localStorage',
        isEncrypt: false,
        key: '',
        value: ''
      };
      storage.key = type;
      storage.value = clickDate;
      setStorage(storage);
    };

    var fnCallLineChild = function fnCallLineChild() {
      lineChildRef.value.doLineDraw();
    };

    var getOptionData = function getOptionData(pattern, time) {
      store.dispatch('fetchChannelList', {
        searchParams: {
          pattern: pattern,
          time: time
        }
      }).then(function () {
        fnCallLineChild();
      });
    };

    var getDateData = function getDateData() {
      dayTime.value = getStorage({
        type: 'localStorage',
        key: 'dayTime',
        isEncrypt: false
      }) || dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      weekTime.value = getStorage({
        type: 'localStorage',
        key: 'weekTime',
        isEncrypt: false
      }) || "".concat(dayjs().subtract(7, 'day').format('YYYY-MM-DD'), "~").concat(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
      monthTime.value = getStorage({
        type: 'localStorage',
        key: 'monthTime',
        isEncrypt: false
      }) || dayjs().subtract(1, 'month').format('YYYY-MM');
    };

    var onConfirmDay = function onConfirmDay(value) {
      calendarDayVisible.value = false;
      dayTime.value = dayjs(value).format('YYYY-MM-DD');
      getOptionData('date', dayTime.value);
      handleOkCalandar('dayTime', dayTime.value);
    };

    var onConfirmWeek = function onConfirmWeek(values) {
      calendarWeekVisible.value = false;

      var _values = _slicedToArray(values, 2),
          start = _values[0],
          end = _values[1];

      weekTime.value = dayjs(start).format('YYYY-MM-DD') + '~' + dayjs(end).format('YYYY-MM-DD');
      getOptionData('week', weekTime.value.replace('~', ','));
      handleOkCalandar('weekTime', weekTime.value);
    };

    var onConfirmMonth = function onConfirmMonth(value) {
      calendarMonthVisible.value = false;
      monthTime.value = dayjs(value).format('YYYY-MM');
      getOptionData('month', monthTime.value);
      handleOkCalandar('monthTime', monthTime.value);
    };

    var setShowData = function setShowData() {
      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      showData.end = new Date(year, month - 1, day); // 当前月份 - 可展示月份 < 1  0或负数

      if (month - showMonth.value < 1) {
        year--; // 总月数 - （ 可展示月份 - 1）

        month = 12 - (showMonth.value - month);
      } else {
        month = month - showMonth.value;
        month--;
      }

      showData.start = new Date(year, month - 1, day);
    };

    setShowData();
    getDateData();
    onMounted(function () {
      getOptionData('week', weekTime.value.replace('~', ','));
    });
    return {
      tagVisible: tagVisible,
      dayTime: dayTime,
      weekTime: weekTime,
      monthTime: monthTime,
      calendarDayVisible: calendarDayVisible,
      calendarWeekVisible: calendarWeekVisible,
      calendarMonthVisible: calendarMonthVisible,
      showData: showData,
      lineChildRef: lineChildRef,
      handleChangeTag: handleChangeTag,
      handleOpenCalendar: handleOpenCalendar,
      handleCancelCalendar: handleCancelCalendar,
      handleOkCalandar: handleOkCalandar,
      onConfirmDay: onConfirmDay,
      onConfirmWeek: onConfirmWeek,
      onConfirmMonth: onConfirmMonth
    };
  }
});