import _objectSpread from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import { ref, defineComponent, computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import QRCode from 'qrcodejs2';
import { Toast } from 'vant';
import ProductSelect from './ProductSelect.vue';
import { urlSplit, copyToClipboard } from '../../utils/index';
export default defineComponent({
  components: {
    ProductSelect: ProductSelect
  },
  setup: function setup() {
    var store = useStore();
    var addProduct = ref();
    var toShowProduct = ref(false);
    var dialogErweima = ref(false);
    var productData = computed(function () {
      return store.state.AdminStore.channelList;
    });
    var shortUrlInfo = computed(function () {
      return store.state.AdminStore.shortUrlInfo;
    });
    var retailObj = reactive({
      id: computed(function () {
        return store.state.UserStore.userInfo.retailNum;
      }),
      name: computed(function () {
        return store.state.UserStore.userInfo.nickname;
      })
    });

    var toAdd = function toAdd() {
      // 父组件触发子组件的方法
      toShowProduct.value = true;
    };

    var getAlldata = function getAlldata(data) {
      var channelObj = data.channelObj,
          retailObj = data.retailObj,
          _id = data._id,
          Toshow = data.Toshow;
      toShowProduct.value = Toshow;

      var formData = _objectSpread(_objectSpread({}, data), {}, {
        channelObj: JSON.stringify(channelObj),
        retailObj: JSON.stringify(retailObj),
        statusObj: JSON.stringify({
          channel: '1',
          retail: '1'
        })
      });

      productData.value.push(formData);
      store.dispatch('patchChannel', {
        data: formData,
        urlParams: {
          id: _id
        }
      }).then(function () {
        getChanneAdminlList();
      });
    };

    var overlayHandle = function overlayHandle() {
      addProduct.value.cancleActionSheet();
    };

    var getChanneAdminlList = function getChanneAdminlList() {
      store.dispatch('fetchChannelAdminList', {
        searchParams: {
          retail_id: store.state.UserStore.userInfo.retailNum,
          page: 1,
          limit: 60
        }
      });
    }; // 链接复制


    var copyAddress = function copyAddress(type, item) {
      // id ? {}
      var hostName = '';
      var host = 'https://t.kepinfm.com';
      var url = '/detail/:id';
      var newUrl = urlSplit(url, {
        urlParams: {
          id: item.goods_id
        },
        searchParams: {
          uid: retailObj.id,
          plan: item.channelObj.id
        }
      });

      switch (type) {
        case 'origin':
          hostName = host + newUrl;
          copyToClipboard(hostName);
          break;

        case 'short':
          hostName = host + newUrl;
          store.dispatch('postShortUrl', {
            data: {
              url: hostName,
              retail_num: retailObj.id
            }
          }).then(function () {
            copyToClipboard(shortUrlInfo.value);
          });
          break;

        default:
          break;
      }

      Toast.success('复制成功');
    };

    var getQrcode = function getQrcode(url) {
      var obj = document.getElementById('qrcode');

      if (obj) {
        obj.innerHTML = '';
      } // eslint-disable-next-line no-new


      new QRCode('qrcode', {
        text: url,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    };

    var closeDialogErweima = function closeDialogErweima() {
      var obj = document.getElementById('qrcode');

      if (obj) {
        obj.innerHTML = '';
      }

      dialogErweima.value = false;
    };

    var openDialogErweima = function openDialogErweima(item) {
      var host = 'https://t.kepinfm.com';
      var url = '/detail/:id';
      var newUrl = urlSplit(url, {
        urlParams: {
          id: item.goods_id
        },
        searchParams: {
          uid: retailObj.id,
          plan: item.channelObj.id
        }
      });
      var hostName = host + newUrl;
      store.dispatch('postShortUrl', {
        data: {
          url: hostName,
          retail_num: retailObj.id
        }
      }).then(function () {
        getQrcode(shortUrlInfo.value);
      });
      dialogErweima.value = true;
    };

    onMounted(function () {
      getChanneAdminlList();
    });
    return {
      addProduct: addProduct,
      productData: productData,
      toShowProduct: toShowProduct,
      dialogErweima: dialogErweima,
      toAdd: toAdd,
      getAlldata: getAlldata,
      overlayHandle: overlayHandle,
      copyAddress: copyAddress,
      openDialogErweima: openDialogErweima,
      closeDialogErweima: closeDialogErweima
    };
  }
});