import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import { shuffle } from 'lodash-es';
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue';
import { Toast } from 'vant';
import { setStorage, getStorage } from '../../utils/storage';
import { sendEvent } from '../../utils/statistic/sendEvent';
import { takeScreenshot } from '../../utils/index';
export default defineComponent({
  name: 'business-component-a',
  emits: ['clickChild'],
  setup: function setup(props, context) {
    var ctx = getCurrentInstance();
    var getIndex = ref();
    var componentName = ctx === null || ctx === void 0 ? void 0 : ctx.type.name;
    var imgShow = ref(false);
    var show = ref(false);
    var sourceData = [{
      id: '1',
      source: 'pyq',
      name: '朋友圈'
    }, {
      id: '2',
      source: 'db',
      name: '豆瓣'
    }, {
      id: '3',
      source: 'xhs',
      name: '小红书'
    }, {
      id: '4',
      source: 'wx',
      name: '微信'
    }, {
      id: '5',
      source: 'wxq',
      name: '微信群'
    }, {
      id: '6',
      source: 'zh',
      name: '知乎'
    }, {
      id: '7',
      source: 'nkw',
      name: '牛客网'
    }, {
      id: '8',
      source: 'dy',
      name: '抖音'
    }];

    var handleClickSource = function handleClickSource(item, index) {
      var pathName = location.pathname;
      getIndex.value = index; // 发送渠道来源点击事件

      sendEvent({
        category: 'scrm',
        action: 'click',
        label: "".concat(pathName, "|").concat(componentName),
        value: item.source
      });
      var config = {
        type: 'localStorage',
        isEncrypt: false,
        key: componentName,
        value: item,
        time: Date.now()
      };
      setStorage(config);
      clickNode2Parent();
    };

    var clickNode2Parent = function clickNode2Parent() {
      context.emit('clickChild', false);
    }; // 点击截图


    var clickScreenShotHandle = function clickScreenShotHandle() {
      imgShow.value = true;
      show.value = true;
      var el = document.getElementById('business-component-a-canvas-area');
      takeScreenshot(el).then(function (res) {
        var screentShotImg = document.getElementById('screent_shot_img');
        setTimeout(function () {
          if (screentShotImg.src === '') {
            var df = new FileReader();
            df.readAsDataURL(res);

            df.onload = function (e) {
              var _e$target;

              screentShotImg.src = (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.result;
            };
          }
        }, 400);
        Toast.success('请长按保存!');
      });
    };

    var upsetSourceData = function upsetSourceData(data) {
      return shuffle(data);
    };

    sourceData = upsetSourceData(sourceData);
    onMounted(function () {
      var clickStore = getStorage({
        type: 'localStorage',
        isEncrypt: false,
        key: componentName
      });

      if (clickStore) {
        context.emit('clickChild', false);
      }
    });
    return {
      show: show,
      imgShow: imgShow,
      sourceData: sourceData,
      getIndex: getIndex,
      handleClickSource: handleClickSource,
      clickScreenShotHandle: clickScreenShotHandle
    };
  }
});