import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import { pick } from 'lodash-es';
import QRCode from 'qrcodejs2';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setStorage, getStorage } from '../../utils/storage';
import { WeChatJSBridge, isWeixin } from '../../utils/wechat';
import { toLine } from '../../utils/index';
export default defineComponent({
  setup: function setup() {
    var store = useStore();
    var route = useRoute();
    var payShow = ref('wx');
    var planShow = ref(false);
    var goodDetailId = ref(''); // 存储至本地 localStorage

    var currentComponentName = ref('BusinessComponentA');
    var channelNameStore = ref('');
    var goodDetailData = computed(function () {
      return store.state.GoodsStore.goodInfo;
    });
    var showGoodQr = ref(false); // 弹出支付宝二维码

    var goodQrcode = ref();

    var getRoute2Storage = function getRoute2Storage() {
      var storageConfig = {
        key: 'kp_shop_detail_v1',
        value: {
          goodId: route.params.id
        },
        type: 'localStorage',
        isEncrypt: true
      };
      var goodDetailId2Storage = getStorage(pick(storageConfig, ['key', 'type', 'isEncrypt']));

      if (!goodDetailId2Storage) {
        setStorage(storageConfig);
      }

      goodDetailId.value = route.params.id ? route.params.id : goodDetailId2Storage === null || goodDetailId2Storage === void 0 ? void 0 : goodDetailId2Storage.goodId;
    };

    onMounted(function () {
      payShow.value = isWeixin() ? 'wx' : 'zfb';
      getRoute2Storage();
      store.dispatch('fetchGoodInfo', {
        urlParams: {
          id: goodDetailId.value
        }
      });
    });

    if (Object.keys(route.query).includes('plan')) {
      switch (route.query.plan) {
        case 'a':
          currentComponentName.value = 'BusinessComponentA';
          channelNameStore.value = toLine(currentComponentName.value);
          break;

        default:
          break;
      }

      planShow.value = true;
    }

    var click2Child = function click2Child(data) {
      planShow.value = data;
    };

    var clickPayHandle = function clickPayHandle() {
      payShow.value = payShow.value === 'wx' ? 'zfb' : 'wx';
    }; // 点击去付款


    var clickPaymentHandle = function clickPaymentHandle() {
      var channelStoreConfig = {
        key: channelNameStore.value,
        type: 'localStorage',
        isEncrypt: false
      };
      var channelInfo = getStorage(channelStoreConfig);
      var storageConfig = {
        key: 'kp_shop_openid_v1',
        type: 'localStorage',
        isEncrypt: true
      };
      var localOpenid = getStorage(storageConfig);
      store.dispatch('fetchOrder', {
        data: {
          price: goodDetailData.value.price,
          goods_id: goodDetailId.value,
          payMethod: payShow.value,
          openid: localOpenid,
          addInfo: JSON.stringify({
            channelSource: channelInfo ? channelInfo === null || channelInfo === void 0 ? void 0 : channelInfo.source : '',
            distributionSource: route.query.uid
          })
        }
      }).then(function (res) {
        if (payShow.value === 'wx') {
          WeChatJSBridge(res);
        }

        if (payShow.value === 'zfb') {
          var url = res.url;
          window.location.href = url;
        }
      });
    }; // 弹出支付宝二维码


    var onGoodQrHander = function onGoodQrHander() {
      var url = window.location.href;
      showGoodQr.value = true;
      var qrWrapper = document.getElementById('goodqrcode');

      if (!(qrWrapper !== null && qrWrapper !== void 0 && qrWrapper.innerHTML)) {
        setTimeout(function () {
          goodQrcode.value.innerHTML = ''; // eslint-disable-next-line no-new

          new QRCode(goodQrcode.value, {
            width: 258,
            height: 258,
            text: url,
            render: 'canvas',
            background: '#f0f',
            foreground: '#ff0',
            correctLevel: QRCode.CorrectLevel.H
          });
        });
      }
    };

    var showGoodQrHandler = function showGoodQrHandler() {
      showGoodQr.value = !showGoodQr.value;
    };

    return {
      planShow: planShow,
      payShow: payShow,
      goodQrcode: goodQrcode,
      showGoodQr: showGoodQr,
      goodDetailData: goodDetailData,
      currentComponentName: currentComponentName,
      click2Child: click2Child,
      clickPayHandle: clickPayHandle,
      clickPaymentHandle: clickPaymentHandle,
      onGoodQrHander: onGoodQrHander,
      showGoodQrHandler: showGoodQrHandler
    };
  }
});