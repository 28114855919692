import { defineComponent, ref } from 'vue';
import { randomNum } from '@/utils';
export default defineComponent({
  setup: function setup() {
    var img = ref();
    var codes = ['https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/555.jpg', 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/666.jpg', 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/777.jpg', 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/888.jpg', 'https://kepinfm.oss-cn-beijing.aliyuncs.com/code/person/999.jpg'];
    img.value = codes[randomNum(5, -1)];
    return {
      img: img
    };
  }
});