import { defineComponent, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { parseTime } from '@/utils/index';
export default defineComponent({
  setup: function setup() {
    var store = useStore();
    var tableData = computed(function () {
      return store.state.AdminStore.inforTableList;
    });
    onMounted(function () {
      store.dispatch('fetchInforTableList', {
        searchParams: {
          page: 1,
          limit: 500
        }
      });
    });

    var timeSortMethodHandle = function timeSortMethodHandle(a, b) {
      return a.created_time > b.created_time;
    };

    var openResumeFileUrlHandle = function openResumeFileUrlHandle(url) {
      window.open(decodeURIComponent(url));
    };

    return {
      tableData: tableData,
      parseTime: parseTime,
      timeSortMethodHandle: timeSortMethodHandle,
      openResumeFileUrlHandle: openResumeFileUrlHandle
    };
  }
});