import "core-js/modules/es.array.concat.js";
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { find } from 'lodash-es';
export default defineComponent({
  setup: function setup() {
    var store = useStore();
    var route = useRoute();
    var tableData = computed(function () {
      return store.state.AdminStore.inforTableList;
    });
    var vTable = ref();

    var exportHTML = function exportHTML() {
      var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " + "xmlns:w='urn:schemas-microsoft-com:office:word' " + "xmlns='http://www.w3.org/TR/REC-html40'>" + "<head><meta charset='utf-8'><title>校招助手用户档案</title></head><body>";
      var footer = '</body></html>';
      var contentHtml = document.getElementById('word-template');
      var sourceHTML = header + contentHtml.innerHTML + footer;
      var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
      var fileDownload = document.createElement('a');
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "".concat(vTable.value.staff).concat(vTable.value.staffNum, "-").concat(vTable.value.username, "-\u5B66\u5458\u6863\u6848.doc");
      fileDownload.click();
      document.body.removeChild(fileDownload);
    };

    onMounted(function () {
      vTable.value = find(tableData.value, ['_id', route.query._id]);
    });

    var uploadDoc = function uploadDoc() {
      exportHTML();
    };

    return {
      vTable: vTable,
      uploadDoc: uploadDoc
    };
  }
});