import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { CONSULTANTS } from '@/utils/index';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();

    var toSheetPageHandle = function toSheetPageHandle(item) {
      router.push({
        name: 'StudentSheet',
        query: {
          v: item.value
        }
      });
    };

    return {
      CONSULTANTS: CONSULTANTS,
      toSheetPageHandle: toSheetPageHandle
    };
  }
});